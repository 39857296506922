import { GET_PREPARE_LIST } from '@/core/services/store/calender.module';
import { CALENDAR_TYPE } from '@/core/plugins/constants';
import { createNamespacedHelpers } from 'vuex';
const { mapActions, mapState } = createNamespacedHelpers('calender');

export const calenderMixin = {
  data() {
    return {
      calendarType: 'week',
      weekday: [0, 1, 2, 3, 4, 5, 6],

      colorBgArr: [
        '#FFF7C0',
        '#E4F5F5',
        '#E6F6ED',
        '#F4EFFF',
        '#FFE3E3',
        '#FEEDDC',
      ],
      colorArr: [
        '#6D28D9',
        '#FDB913',
        '#21A567',
        '#01645A',
        '#AF0000',
        '#F58220',
      ],

      ready: false,
    };
  },

  computed: {
    ...mapState(['listCoaches', 'listTypes']),

    cal() {
      return this.ready ? this.$refs.calendar : null;
    },

    nowY() {
      return this.cal ? this.cal.timeToY(this.cal.times.now) + 'px' : '-10px';
    },
  },

  created() {
    this.GET_PREPARE_LIST();
  },

  mounted() {
    this.ready = true;
    this.scrollToTime();
    this.updateTime();
  },

  methods: {
    ...mapActions({ GET_PREPARE_LIST }),

    getCurrentTime() {
      return this.cal
        ? this.cal.times.now.hour * 60 + this.cal.times.now.minute
        : 0;
    },

    scrollToTime() {
      const time = this.getCurrentTime();
      const first = Math.max(0, time - (time % 30) - 30);

      this.cal.scrollToTime(first);
    },

    updateTime() {
      setInterval(() => this.cal.updateTimes(), 60 * 1000);
    },

    getDayBodyStyle({ type }) {
      return {
        'background-color': this.getBgColor(type),
        'border-left': `3px solid ${this.getTextColor(type)}`,
        'border-radius': '3px 0 0 3px',
      };
    },

    getTextColor(type) {
      const color = Object.values(CALENDAR_TYPE).find(
        (item) => item.value === type.toString(),
      )?.color;
      return color;
    },

    getBgColor(type) {
      return this.colorBgArr[Number(type)] || this.colorBgArr[0];
    },

    formatQueryParams(data) {
      const payload = {
        fromDate: data.fromDate,
        toDate: data.toDate,
      };

      const params = new URLSearchParams();
      for (const property in payload) {
        params.append(property, payload[property]);
      }
      if (data.filterCoach?.length) {
        data.filterCoach.forEach((el) => {
          params.append('accountIds', el);
        });
      }
      if (data.filterTypes?.length) {
        data.filterTypes.forEach((el) => {
          params.append('calendarTypes', el);
        });
      }

      return params;
    },

    formatWeekday({ weekday }) {
      switch (weekday) {
        case 0:
          return 'CN';
        case 1:
          return 'T2';
        case 2:
          return 'T3';
        case 3:
          return 'T4';
        case 4:
          return 'T5';
        case 5:
          return 'T6';
        case 6:
          return 'T7';

        default:
          return 'CN';
      }
    },

    formatDateHeader(date) {
      const [year, month] = date.split('-');
      if (!month) return `Năm ${year}`;
      return `Tháng ${month} ${year}`;
    },

    formatCalenderHeader({ fromDate, toDate }) {
      const fDate = new Date(fromDate * 1000);
      const tDate = new Date(toDate * 1000);

      return `${this.formatDateLocale(fDate)} - ${this.formatDateLocale(
        tDate,
      )}`;
    },
    toastCreateError() {
      this.$toastr.e({
        title: 'Thất bại!',
        msg: 'Khung giờ bạn chọn đang bận. <br/> Vui lòng chọn khung giờ khác',
      });
    },

    toastCreateSuccess() {
      this.$toastr.s({
        title: 'Thành công!',
        msg: 'Tạo mới lịch hẹn thành công',
      });
    },

    toastUpdateSuccess() {
      this.$toastr.s({
        title: 'Thành công!',
        msg: 'Chỉnh sửa lịch thành công',
      });
    },
  },
};
